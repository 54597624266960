import { defineStore } from 'pinia'

export const useAdminStore = defineStore('adminStore', {
  state: () => ({
    list: [],
  }),

  actions: {
    async fetches(params: any) {
      return await useApi<any>(createUrl('/admins', {
        query: params,
      }))
    },

    async fetchAll() {
      return await $api<any>('/admins/all')
    },

    async withName() {
      return await $api<any>('/admins/with_name')
    },

    async lists() {
      return await useApi<any>('/admins/all')
    },

    async add(data: any) {
      return await $api('/admins', {
        method: 'POST',
        body: data,
      })
    },

    async update(data: any) {
      return await $api(`/admins/${data.id}`, {
        method: 'PUT',
        body: data,
      })
    },

    async delete(id: number) {
      return await $api(`/admins/${id}`, {
        method: 'DELETE',
      })
    },

    async download(data: any) {
      return await $api<any>('/admins/download', {
        params: data,
      })
    },

    async upload(data: any) {
      return await $api('/admins/upload', {
        method: 'POST',
        body: data,
      })
    },
  },
})
