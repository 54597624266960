export const COOKIE_MAX_AGE_1_YEAR = 365 * 24 * 60 * 60

export const selectableMessage = [
  { title: '未読あり', value: '未読あり', condition: '$gt', key: 0 },
  { title: '未読なし', value: '未読なし', condition: '$eq', key: 0 },
]

export const adminProgressStatus = [
  'エントリー前',
  'エントリーまち',
  '再提案前',
  '提案中',
  'エントリー中',
  '確定',
  '取り下げ依頼',
  '取り下げ処理中',
  'キャンセル処理待ち',
  'キャンセル処理中',
  '取り下げ完了',
  '①CL 仕なし・売なし',
  '②CL 仕あり・売あり',
  '③CL 仕なし・売あり',
  '④CL 仕あり・売なし',
  '無償キャンセル',
  '有償キャンセル',
  '施設NG',
  'クライアントNG',
  '予約済',
  'その他NG',
  '同商材先約NG',
  '商材NG',
  '日程NG',
]

export const adminProgressList = [
  { title: 'エントリー前', value: 'エントリー前', background: '#FFCCFF' },
  { title: 'エントリーまち', value: 'エントリーまち' },
  { title: '再提案前', value: '再提案前' },
  { title: '提案中', value: '提案中' },
  { title: 'エントリー中', value: 'エントリー中' },
  { title: '確定', value: '確定', props: { disabled: true } },
  { title: '取り下げ依頼', value: '取り下げ依頼', props: { disabled: true } },
  { title: '取り下げ処理中', value: '取り下げ処理中' },
  { title: 'キャンセル処理待ち', value: 'キャンセル処理待ち', props: { disabled: true } },
  { title: 'キャンセル処理中', value: 'キャンセル処理中' },
  { title: '取り下げ完了', value: '取り下げ完了' },
  { title: '①CL 仕なし・売なし', value: '①CL 仕なし・売なし' },
  { title: '②CL 仕あり・売あり', value: '②CL 仕あり・売あり' },
  { title: '③CL 仕なし・売あり', value: '③CL 仕なし・売あり' },
  { title: '④CL 仕あり・売なし', value: '④CL 仕あり・売なし' },
  { title: '無償キャンセル', value: '無償キャンセル' },
  { title: '有償キャンセル', value: '有償キャンセル' },
  { title: '施設NG', value: '施設NG' },
  { title: 'クライアントNG', value: 'クライアントNG' },
  { title: '予約済', value: '予約済' },
  { title: 'その他NG', value: 'その他NG' },
  { title: '同商材先約NG', value: '同商材先約NG' },
  { title: '商材NG', value: '商材NG' },
  { title: '日程NG', value: '日程NG' },
]

export const selectableOwnerStatus = [
  { title: '施設あり', value: '施設あり', condition: '$eq', key: 1 },
  { title: '施設なし', value: '施設なし', condition: '$eq', key: 0 },
]

export const selectableNewEntry = [
  { title: 'あり', value: 'あり', condition: '$eq', key: 1 },
  { title: 'なし', value: 'なし', condition: '$eq', key: 0 },
]

export const selectableSales = ref([
  { title: '業務', value: '業務', condition: '$eq', key: 2 },
  { title: '営業', value: '営業', condition: '$eq', key: 1 },
  { title: 'CO', value: 'CO', condition: '$eq', key: 3 },
  { title: '施設', value: '施設', condition: '$eq', key: 5 },
  { title: '委託', value: '委託', condition: '$eq', key: 4 },
  { title: '再提案', value: '再提案', condition: '$eq', key: 6 },
  { title: '通常', value: '通常', condition: '$eq', key: 0 },
])

export const selectableCompletionReport = [
  { title: '申請書：有', value: '申請書：有', condition: '$eq', key: 1 },
  { title: '申請書：無', value: '申請書：無', condition: '$eq', key: 0 },
]

export const selectableEntrySheet = [
  { title: '完了報告：有', value: '完了報告：有', condition: '$eq', key: 1 },
  { title: '完了報告：無し', value: '完了報告：無し', condition: '$eq', key: 0 },
]

export const selectableRequireContract = [
  { title: '契約書：有', value: '契約書：有', condition: '$eq', key: 1 },
  { title: '契約書：無', value: '契約書：無', condition: '$eq', key: 0 },
]

export const selectableAdminProgress = [
  { title: 'エントリー前', value: 'エントリー前', condition: '$eq', key: 'エントリー前' },
  { title: 'エントリーまち', value: 'エントリーまち', condition: '$eq', key: 'エントリーまち' },
  { title: '再提案前', value: '再提案前', condition: '$eq', key: '再提案前' },
  { title: '提案中', value: '提案中', condition: '$eq', key: '提案中' },
  { title: 'エントリー中', value: 'エントリー中', condition: '$eq', key: 'エントリー中' },
  { title: '確定', value: '確定', condition: '$eq', key: '確定' },
  { title: '取り下げ依頼', value: '取り下げ依頼', condition: '$eq', key: '取り下げ依頼' },
  { title: '取り下げ処理中', value: '取り下げ処理中', condition: '$eq', key: '取り下げ処理中' },
  { title: 'キャンセル処理待ち', value: 'キャンセル処理待ち', condition: '$eq', key: 'キャンセル処理待ち' },
  { title: 'キャンセル処理中', value: 'キャンセル処理中', condition: '$eq', key: 'キャンセル処理中' },
  { title: '取り下げ完了', value: '取り下げ完了', condition: '$eq', key: '取り下げ完了' },
  { title: '①CL 仕なし・売なし', value: '①CL 仕なし・売なし', condition: '$eq', key: '①CL 仕なし・売なし' },
  { title: '②CL 仕あり・売あり', value: '②CL 仕あり・売あり', condition: '$eq', key: '②CL 仕あり・売あり' },
  { title: '③CL 仕なし・売あり', value: '③CL 仕なし・売あり', condition: '$eq', key: '③CL 仕なし・売あり' },
  { title: '④CL 仕あり・売なし', value: '④CL 仕あり・売なし', condition: '$eq', key: '④CL 仕あり・売なし' },
  { title: '無償キャンセル', value: '無償キャンセル', condition: '$eq', key: '無償キャンセル' },
  { title: '有償キャンセル', value: '有償キャンセル', condition: '$eq', key: '有償キャンセル' },
  { title: '施設NG', value: '施設NG', condition: '$eq', key: '施設NG' },
  { title: 'クライアントNG', value: 'クライアントNG', condition: '$eq', key: 'クライアントNG' },
  { title: '予約済', value: '予約済', condition: '$eq', key: '予約済' },
  { title: 'その他NG', value: 'その他NG', condition: '$eq', key: 'その他NG' },
  { title: '同商材先約NG', value: '同商材先約NG', condition: '$eq', key: '同商材先約NG' },
  { title: '商材NG', value: '商材NG', condition: '$eq', key: '商材NG' },
  { title: '日程NG', value: '日程NG', condition: '$eq', key: '日程NG' },
]

export const selectableClientProgress = [
  { title: 'エントリー中', value: 'エントリー中', condition: '$eq', key: 'エントリー中' },
  { title: '承認まち', value: '承認まち', condition: '$eq', key: '承認まち' },
  { title: '取り下げ依頼', value: '取り下げ依頼', condition: '$eq', key: '取り下げ依頼' },
  { title: '取り下げ処理中', value: '取り下げ処理中', condition: '$eq', key: '取り下げ処理中' },
  { title: 'キャンセル処理まち', value: 'キャンセル処理まち', condition: '$eq', key: 'キャンセル処理まち' },
  { title: 'キャンセル処理中', value: 'キャンセル処理中', condition: '$eq', key: 'キャンセル処理中' },
  { title: '取り下げ完了', value: '取り下げ完了', condition: '$eq', key: '取り下げ完了' },
  { title: '無償キャンセル', value: '無償キャンセル', condition: '$eq', key: '無償キャンセル' },
  { title: '有償キャンセル', value: '有償キャンセル', condition: '$eq', key: '有償キャンセル' },
  { title: 'NG', value: 'NG', condition: '$eq', key: 'キャンセル処理中' },
  { title: '予約済', value: '予約済', condition: '$eq', key: 'NG' },
  { title: '確定', value: '確定', condition: '$eq', key: '確定' },
  { title: '同商材先約NG', value: '同商材先約NG', condition: '$eq', key: '同商材先約NG' },
  { title: '日程NG', value: '日程NG', condition: '$eq', key: '日程NG' },
  { title: '商材NG', value: '商材NG', condition: '$eq', key: '商材NG' },
  { title: 'その他NG', value: 'その他NG', condition: '$eq', key: 'その他NG' },
]

export const selectableOwnerProgress = [
  { title: 'エントリー中', value: 'エントリー中', condition: '$eq', key: 'エントリー中' },
  { title: '取り下げ依頼中', value: '取り下げ依頼中', condition: '$eq', key: '取り下げ依頼中' },
  { title: '提案中', value: '提案中', condition: '$eq', key: '提案中' },
  { title: 'キャンセル依頼中', value: 'キャンセル依頼中', condition: '$eq', key: 'キャンセル依頼中' },
  { title: '取り下げ完了', value: '取り下げ完了', condition: '$eq', key: '取り下げ完了' },
  { title: '有償キャンセル', value: '有償キャンセル', condition: '$eq', key: '有償キャンセル' },
  { title: '無償キャンセル', value: '無償キャンセル', condition: '$eq', key: '無償キャンセル' },
  { title: '予約済', value: '予約済', condition: '$eq', key: '予約済' },
  { title: '確定', value: '確定', condition: '$eq', key: '確定' },
  { title: '同商材先約NG', value: '同商材先約NG', condition: '$eq', key: '同商材先約NG' },
  { title: '日程NG', value: '日程NG', condition: '$eq', key: '日程NG' },
  { title: '商材NG', value: '商材NG', condition: '$eq', key: '商材NG' },
  { title: 'その他NG', value: 'その他NG', condition: '$eq', key: 'その他NG' },
]

export const selectableDocumentProgress = [
  { title: '最終確認中', value: '最終確認中', condition: '$eq', key: '最終確認中' },
  { title: '未作成', value: '未作成', condition: '$eq', key: '未作成' },
  { title: '開催情報依頼中', value: '開催情報依頼中', condition: '$eq', key: '開催情報依頼中' },
  { title: '施設書類待ち', value: '施設書類待ち', condition: '$eq', key: '施設書類待ち' },
  { title: '作成要', value: '作成要', condition: '$eq', key: '作成要' },
  { title: '書類提出前', value: '書類提出前', condition: '$eq', key: '書類提出前' },
  { title: '二次書類待ち', value: '二次書類待ち', condition: '$eq', key: '二次書類待ち' },
  { title: '完了', value: '完了', condition: '$eq', key: '完了' },
  { title: '再作成要', value: '再作成要', condition: '$eq', key: '再作成要' },
  { title: '再完了', value: '再完了', condition: '$eq', key: '再完了' },
  { title: '完了報告作成', value: '完了報告作成', condition: '$eq', key: '完了報告作成' },
  { title: '完了報告提出済', value: '完了報告提出済', condition: '$eq', key: '完了報告提出済' },
  { title: '再作成情報依頼中', value: '再作成情報依頼中', condition: '$eq', key: '再作成情報依頼中' },
  { title: '再作成書類提出前', value: '再作成書類提出前', condition: '$eq', key: '再作成書類提出前' },
]

export const selectableDocumentProgressStatus = [
  '最終確認中',
  '未作成',
  '開催情報依頼中',
  '施設書類待ち',
  '作成要',
  '書類提出前',
  '二次書類待ち',
  '完了',
  '再作成要',
  '再完了',
  '完了報告作成',
  '完了報告提出済',
  '再作成情報依頼中',
  '再作成書類提出前',
]

export const documentStatus = [
  '最終確認中',
  '未作成',
  '開催情報依頼中',
  '施設書類待ち',
  '作成要',
  '書類提出前',
  '二次書類待ち',
  '完了',
  '再作成情報依頼中',
  '再作成要',
  '再完了',
  '再作成書類提出前',
  '完了報告作成',
  '完了報告提出済',
]

export const selectableShowMoney = [
  { title: '開示', value: '開示', condition: '$eq', key: 1 },
  { title: '非開示', value: '非開示', condition: '$eq', key: 0 },
]
